import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../notfound/NotFound";
import {
  editDeposit,
  getDepositById,
  getAllBranchOfficesByBu,
  getCategoriesFiltered,
  processExcelTemplate,
  getHistoricalStockDeposit,
} from "../../api/Services";
import { BusinessUniteContext } from "../../components/laoyut/Layout";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import ArticleStock from "../../components/articlestock/ArticleStock";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  TreeSelect,
  InputNumber,
  Modal,
  DatePicker,
  Table,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewDeposit.module.scss";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";

const ViewDeposit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    contextValue: businessUnitSelected,
    updateContextValue: setBusinessUnitSelected,
  } = useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(true);
  const [deposit, setDeposit] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);
  const [branchOfficesSelected, setBranchOfficesSelected] = useState<any[]>([]);
  const [historicalStockActualPage, setHistoricalStockActualPage] = useState(1);
  const [totalSizeHistoricalStock, setTotalSizeHistoricalStock] = useState(0);
  const [historicalStocks, setHistoricalStock] = useState<any[]>([]);
  const [selectedDates, setSelectedDates] = useState<[any, any] | null>(null);

  const [editForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_DEPOSITS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_DEPOSITS)) {
      handleFetchDeposit();
    }
  }, []);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_DEPOSITS)) {
      handleFetchHistoricalStock();
    }
  }, [selectedDates]);

  const handleFetchHistoricalStock = async (
    pagination = historicalStockActualPage
  ) => {
    const params: any = {};

    if (selectedDates) {
      const [startDate, endDate] = selectedDates;
      if (startDate && endDate) {
        params.startDate = startDate.format("YYYY-MM-DD");
        params.endDate = endDate.format("YYYY-MM-DD");
      }
    }
    const response = await getHistoricalStockDeposit(id!, pagination, {
      startDate: params.startDate,
      endDate: params.endDate,
    });
    console.log(response);
    setHistoricalStockActualPage(pagination);
    setTotalSizeHistoricalStock(response.totalElements);
    setHistoricalStock(response.stocks);
  };

  useEffect(() => {
    if (
      deposit &&
      !deposit.businessUnits
        .map((businessUnit: any) => businessUnit.id)
        .includes(businessUnitSelected) &&
      businessUnitSelected &&
      !loading
    ) {
      navigate("/admin/deposits");
    }
  }, [businessUnitSelected]);

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };
  const getOptionsTree = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push({
        title: item.businessUnit.name,
        value: item.businessUnit.name,
        key: item.businessUnit.name,
        children: item.branchOffices.map((bu: any) => ({
          title: bu.name,
          value: bu.id,
          key: bu.id,
        })),
      });
    });

    return options;
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };
  const handleFetchDeposit = async () => {
    let response = await getDepositById(id!);
    if (response.businessUnits.length > 0) {
      const branchOffices = await getAllBranchOfficesByBu(
        response.businessUnits.map((bu: any) => bu.id)
      );
      response.branchOfficesOptions = branchOffices;
    }
    setBranchOfficesSelected(
      response.branchOffices.map((item: any) => ({
        id: item.branchOffice.id,
        name: item.branchOffice.name,
      }))
    );

    setCategories(response.categories);
    setDeposit(response);
    setBusinessUnitSelected(response.businessUnits[0].id);
    setLoading(false);
  };

  const handleEditDeposit = async () => {
    await editForm.validateFields().then(async (data) => {
      setLoading(true);
      const id = deposit.id;
      const {
        name,
        externalId,
        capacity,
        branchOfficesIds,
        address,
        categoriesIds,
        capacityInUnits,
        capacityDispatchUnits,
        dispatchFrequency,
        capacityReception,
        storageCostPerUnit,
        minimumStock,
        maximumStock,
        latitude,
        longitude,
      } = data;

      const branchOfficesDeposit = branchOfficesIds?.map((id: any) => ({
        branchOfficeId: id,
        distance: data[`distance-${id}`],
      }));

      let response = await editDeposit(id, {
        name,
        capacity,
        externalId,
        branchOffices: branchOfficesDeposit,
        address,
        businessUnitsIds: [businessUnitSelected] ?? [],
        categoriesIds: categoriesIds ?? [],
        capacityInUnits,
        capacityDispatchUnits,
        dispatchFrequency,
        capacityReception,
        storageCostPerUnit,
        minimumStock,
        maximumStock,
        latitude,
        longitude,
      });

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
        handleFetchDeposit();
      } else message.error(t("Common-EditErrorMessage"));
      setLoading(false);
    });
  };

  const handleCancelButton = () => {
    editForm.resetFields();
  };

  const showImportModal = () => {
    setIsImportModalVisible(true);
    setImportErrors("");
  };

  const handleCancelImportModal = () => {
    setIsImportModalVisible(false);
  };

  const excelButtons = (
    <>
      <Button
        onClick={showImportModal}
        className={styles.importButton}
        type="primary"
      >
        {t("Common-Import")}
      </Button>
      <Button
        onClick={() =>
          handleDownloadTemplate("deposits/stocks", setDownloadingTemplate)
        }
        loading={downloadingTemplate}
        className={styles.importButton}
        type="primary"
      >
        {t("Common-DownloadTemplate")}
      </Button>
    </>
  );

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate("branchOffices/stocks", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchDeposit();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const renderBranchOfficeDistanceFields = () => {
    return branchOfficesSelected.map((branchOfficeOption: any) => {
      let foundBranchOffice: any = null;

      deposit.branchOffices.some((boDeposit: any) => {
        if (boDeposit.branchOffice.id === branchOfficeOption.id)
          foundBranchOffice = boDeposit;
        return !!foundBranchOffice;
      });
      return (
        <Form.Item
          key={branchOfficeOption.id}
          label={`${t("Common-Distance")} ${branchOfficeOption.name}`}
          name={`distance-${branchOfficeOption.id}`}
          initialValue={foundBranchOffice?.distance}
        >
          <InputNumber placeholder={t("Common-Distance")} />
        </Form.Item>
      );
    });
  };

  const handleBranchOfficeChange = (
    selectedBranchOfficeIds: any,
    labelList: any
  ) => {
    const selectedBranchOffices = selectedBranchOfficeIds.map(
      (id: any, index: number) => ({
        id: id,
        name: labelList[index],
      })
    );

    setBranchOfficesSelected(selectedBranchOffices);

    const currentValues = editForm.getFieldsValue();
    const updatedValues = { ...currentValues };

    branchOfficesSelected.forEach((officeId) => {
      if (!selectedBranchOfficeIds.includes(officeId)) {
        delete updatedValues[`distance-${officeId}`];
      }
    });

    editForm.setFieldsValue(updatedValues);
  };

  const editedBranchOffices: number[] = [];
  deposit?.branchOffices?.forEach((branchOffice: any) =>
    editedBranchOffices.push(branchOffice.branchOffice.id)
  );

  const historicalStockColumns = [
    {
      title: t("Common-Sku"),
      dataIndex: "sku",
      render: (sku: any) => sku.name,
    },
    {
      title: t("Common-Date"),
      dataIndex: "date",
      render: (date: string) => date.split("T")[0],
    },
    {
      title: t("Common-Stock"),
      dataIndex: "stock",
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !deposit ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Deposit")}</h1>
        {hasPermission(privilegesEnum.WRITE_DEPOSITS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEditDeposit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.depositInfoWrapper}>
          <Form
            name="viewdeposit"
            layout={"vertical"}
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={editForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              initialValue={deposit?.name}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              label={t("Common-ValuedInventory")}
              name="valuedInventory"
              initialValue={deposit?.totalCostsByArticle.reduce(
                (acc: number, item: any) => {
                  return acc + item.cost;
                },
                0
              )}
              className={styles.formItem}
            >
              <Input
                disabled={true}
                placeholder={t("Common-ValuedInventory")}
              />
            </Form.Item>

            <Form.Item
              label={t("Common-CapacityStorageInmt3")}
              name="capacity"
              initialValue={deposit?.capacity}
              className={styles.formItem}
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-CapacityStorageInmt3")}
                addonAfter={t("Common-mtcube")}
                className={styles.inputNumber}
              />
            </Form.Item>

            <Form.Item
              initialValue={deposit?.capacityInUnits}
              className={styles.formItem}
              label={t("Common-CapacityStorageInUnits")}
              name="capacityInUnits"
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-CapacityStorageInUnits")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={deposit?.capacityDispatchUnits}
              className={styles.formItem}
              label={t("Common-CapacityDispatchUnits")}
              name="capacityDispatchUnits"
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-CapacityDispatchUnits")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={deposit?.capacityReception}
              className={styles.formItem}
              label={t("Common-CapacityReception")}
              name="capacityReception"
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-CapacityReception")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={deposit?.storageCostPerUnit}
              className={styles.formItem}
              label={t("Common-StorageCostPerUnit")}
              name="storageCostPerUnit"
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-StorageCostPerUnit")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-MinimumStock")}
              name="minimumStock"
              initialValue={deposit?.minimumStock}
              className={styles.formItem}
            >
              <InputNumber
                placeholder={t("Common-MinimumStock")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-MaximumStock")}
              name="maximumStock"
              initialValue={deposit?.maximumStock}
              className={styles.formItem}
            >
              <InputNumber
                placeholder={t("Common-MaximumStock")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={deposit?.dispatchFrequency}
              className={styles.formItem}
              label={t("Common-DispatchFrequency")}
              name="dispatchFrequency"
            >
              <InputNumber
                disabled={!canEdit}
                min={1}
                max={7}
                placeholder={t("Common-DispatchFrequency")}
                className={styles.inputNumber}
              />
            </Form.Item>

            <Form.Item
              label={t("Common-ExternalId")}
              name="externalId"
              initialValue={deposit?.externalId}
              className={styles.formItem}
            >
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>

            <Form.Item
              label={t("Common-Address")}
              name="address"
              initialValue={deposit?.address}
              className={styles.formItem}
            >
              <Input placeholder={t("Common-Address")} />
            </Form.Item>
            <Form.Item
              label={t("Common-Latitude")}
              name="latitude"
              initialValue={deposit?.latitude}
              className={styles.formItem}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-Latitude")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-Longitude")}
              name="longitude"
              initialValue={deposit?.longitude}
              className={styles.formItem}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-Longitude")}
              />
            </Form.Item>
            <Form.Item
              className={styles.formItem}
              label={t("Common-BranchOffices")}
              name="branchOfficesIds"
              initialValue={editedBranchOffices}
            >
              <TreeSelect
                allowClear
                placeholder={t("Common-BranchOffices")}
                treeCheckable={true}
                defaultValue={branchOfficesSelected}
                onChange={handleBranchOfficeChange}
                treeData={getOptionsTree(deposit?.branchOfficesOptions)}
              />
            </Form.Item>
            <div className={styles.distanceWrapper}>
              {renderBranchOfficeDistanceFields()}
            </div>
            <Form.Item
              label={t("Common-Categories")}
              name="categoriesIds"
              className={styles.formItem}
              initialValue={deposit?.categories?.map((cat: any) => cat.id)}
            >
              <Select
                allowClear
                placeholder={t("Common-SearchByName")}
                onSearch={handleSearchCategories}
                showSearch
                mode="multiple"
                optionFilterProp="children"
                defaultValue={deposit?.categories?.map((cat: any) => cat.id)}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptionsCategories(categories)}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.tableWrapper}></div>
        <ArticleStock
          data={deposit.skus}
          header={excelButtons}
          fetchData={handleFetchDeposit}
          type={"deposits"}
          placeId={parseInt(id!)}
          searchByName={false}
        />
        <div className={styles.tableWrapper}>
          <div className={styles.tablesHeader}>
            <h1 className={styles.tableTitle}>{t("Common-HistoricalStock")}</h1>
          </div>
          <div className={styles.filtersWrapper}>
            <div className={styles.filterWrapper}>
              <DatePicker.RangePicker
                className={styles.dateSelector}
                placeholder={[t("Common-Start"), t("Common-End")]}
                onChange={(dates) => setSelectedDates(dates)}
              ></DatePicker.RangePicker>
            </div>
          </div>
          <div>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              rowKey={(record) => record.id}
              columns={historicalStockColumns}
              pagination={{
                total: totalSizeHistoricalStock,
                showSizeChanger: false,
                onChange: handleFetchHistoricalStock,
                current: historicalStockActualPage,
              }}
              dataSource={historicalStocks}
            />
          </div>
        </div>
      </div>
      <Modal
        title={t("Common-ImportEntity", {
          entity: t("Common-Stock"),
        })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelImportModal}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default ViewDeposit;
