import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { editClientLevel, getClientLevelById } from "../../../api/Services";
import {
  hasPermission,
  privilegesEnum,
} from "../../../helpers/PermissionHelper";
import {
  Form,
  Input,
  Button,
  message,
  Spin,
  Select,
  InputNumber,
  Switch,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewClientLevel.module.scss";

const ViewClientLevel = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [clientLevel, setClientLevel] = useState<any>();
  const [loading, setLoading] = useState(true);

  const [editForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_CLIENT_LEVEL);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENT_LEVEL)) {
      handleFetchClientLevel();
    }
  }, []);

  const handleFetchClientLevel = async () => {
    const response = await getClientLevelById(id!);

    setClientLevel(response);
    setLoading(false);
  };

  const handleEdit = async () => {
    await editForm.validateFields().then(async (values) => {
      setLoading(true);
      const {
        name,
        level,
        description,
        ruleType,
        ruleQuantity,
        accumulationFactor,
      } = values;
      let response = await editClientLevel(parseInt(id!), {
        name,
        level,
        description,
        ruleType,
        ruleQuantity,
        accumulationFactor,
      });

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
      } else message.error(t("Common-EditErrorMessage"));

      await handleFetchClientLevel();
      setLoading(false);
    });
  };
  const handleCancelButton = () => {
    editForm.resetFields();
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ClientLevel")}</h1>
        {canEdit && (
          <div className={styles.actionButtonsWrapper}>
            {/*<Button*/}
            {/*  onClick={handleEdit}*/}
            {/*  className={styles.saveButton}*/}
            {/*  type="primary"*/}
            {/*>*/}
            {/*  {t("Common-Save")}*/}
            {/*</Button>*/}
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="clientLevel"
            layout="vertical"
            initialValues={{ remember: true }}
            form={editForm}
            autoComplete="off"
            className={styles.form}
            preserve={false}
          >
            <Form.Item
              initialValue={clientLevel?.level}
              label={t("Common-Level")}
              name="level"
              className={styles.itemWrapper}
              rules={[
                {
                  required: true,
                  message: t("Common-Level"),
                },
              ]}
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-Level")}
              />
            </Form.Item>
            <Form.Item
              initialValue={clientLevel?.name}
              label={t("Common-Name")}
              name="name"
              className={styles.itemWrapper}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              initialValue={clientLevel?.description}
              label={t("Common-Description")}
              name="description"
            >
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item
              initialValue={clientLevel?.accumulationFactor}
              label={t("Common-AccumulationFactor")}
              name="accumulationFactor"
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-AccumulationFactor")}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-RuleType")}
              initialValue={clientLevel?.ruleType}
              name="ruleType"
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("Common-RuleType")}
              >
                <Select.Option value="ACCOUNT_POINTS">
                  {t("Common-AccountPoints")}
                </Select.Option>
                <Select.Option value="MONTHS_IN_PROGRAM">
                  {t("Common-MonthsInProgram")}
                </Select.Option>
                <Select.Option value="TOTAL_SPENT">
                  {t("Common-TotalSpent")}
                </Select.Option>
                <Select.Option value="AMMOUNT_SPENT">
                  {t("Common-AmmountSpent")}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={clientLevel?.ruleQuantity}
              label={t("Common-RuleQuantity")}
              name="ruleQuantity"
            >
              <InputNumber
                className={styles.inputNumber}
                placeholder={t("Common-RuleQuantity")}
              />
            </Form.Item>
            <Form.Item
              initialValue={clientLevel?.active}
              label={t("Common-Active")}
              name="enable"
              rules={[
                {
                  required: true,
                  message: t("Common-Active"),
                },
              ]}
            >
              <Switch defaultChecked={clientLevel?.active} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ViewClientLevel;
