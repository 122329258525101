import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getClientById,
  editClient,
  getAllIdTypes,
  getAllCountries,
  getAllSexTypes,
  getAllStatesByCountry,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  DatePicker,
  Switch,
  Table,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewClient.module.scss";
import NotFound from "../notfound/NotFound";

const ViewClient = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [idTypes, setIdTypes] = useState<any[]>([]);
  const [sexTypes, setSexTypes] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [statesByCountry, setStatesByCountry] = useState<any[]>([]);
  const [client, setClient] = useState<any>();

  const [infoForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_CLIENTS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClient();
      handleFetchCountries();
      handleFetchIdTypes();
      handleFetchSexTypes();
    }
  }, []);

  const handleFetchClient = async () => {
    setLoading(true);

    let response = await getClientById(id!);
    setClient(response);

    setLoading(false);
  };

  const handleFetchIdTypes = async () => {
    const response = await getAllIdTypes();
    setIdTypes(response);
  };

  const handleFetchCountries = async () => {
    const response = await getAllCountries();
    setCountries(response);
  };

  const handleFetchSexTypes = async () => {
    const response = await getAllSexTypes();
    setSexTypes(response);
  };

  const getIdTypesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });
    return options;
  };

  const getCountriesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });
    return options;
  };

  const handleFetchStatesByCountry = async (countryId: number) => {
    const response = await getAllStatesByCountry(countryId);
    setStatesByCountry(response);
  };

  const getSexTypesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCancelButton = () => {
    infoForm.resetFields();
  };

  const handleEdit = async () => {
    setLoading(true);

    const data = infoForm.getFieldsValue();
    data.id = parseInt(id!);
    data.birthdate = data.birthdate
      ? data.birthdate.format("YYYY-MM-DD")
      : null;

    const response = await editClient(data);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      await handleFetchClient();
    } else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const clientSegmentsColumn = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (segment: any) => (
        <a href={`/promotions/clientsegments/${segment.id}/view`}>
          {segment.name}
        </a>
      ),
    },
    {
      title: t("Common-Description"),
      dataIndex: "description",
    },
    {
      title: t("Common-Type"),
      key: "type",
      render: (segment: any) => segment.type.description_es,
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !client ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Client")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-PersonalInfo")} `}</h1>

          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoForm}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Identifier")}
              name="documentNumber"
              initialValue={client?.idNumber}
              rules={[
                {
                  required: true,
                  message: t("Common-Identifier"),
                },
              ]}
            >
              <Input
                placeholder={t("Common-DocumentNumber")}
                disabled={!canEdit}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-FidelityId")}
              name="fidelity"
              initialValue={client?.fidelity}
              rules={[
                {
                  required: true,
                  message: t("Common-FidelityId"),
                },
              ]}
            >
              <Input placeholder={t("Common-FidelityId")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-DocumentType")}
              name="documentType"
              initialValue={client?.idType?.id}
            >
              <Select
                defaultValue={client?.idType?.id}
                placeholder={t("Common-DocumentType")}
                allowClear
                disabled={!canEdit}
              >
                {getIdTypesOptions(idTypes)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Sex")}
              name="sex"
              initialValue={client?.sex.value}
            >
              <Select
                placeholder={t("Common-Sex")}
                allowClear
                defaultValue={client?.sex}
                disabled={!canEdit}
              >
                {getSexTypesOptions(sexTypes)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Birthdate")}
              name="birthdate"
              initialValue={moment(client?.birthdate, "YYYY-MM-DD")}
            >
              <DatePicker
                className={styles.dateSelector}
                defaultValue={moment(client?.birthdate, "YYYY-MM-DD")}
                disabled={!canEdit}
                // onChange={handleChangeDate}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Country")}
              name="country"
              initialValue={client?.country?.id}
            >
              <Select
                placeholder={t("Common-Country")}
                defaultValue={client?.country?.id}
                disabled={!canEdit}
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  handleFetchStatesByCountry(value);
                }}
              >
                {getCountriesOptions(countries)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-City")}
              name="city"
              initialValue={client?.state?.id}
            >
              <Select
                placeholder={t("Common-City")}
                defaultValue={client?.state?.id}
                onChange={(value) => {
                  handleFetchStatesByCountry(value);
                }}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                optionFilterProp="children"
                disabled={!canEdit}
              >
                {getCountriesOptions(statesByCountry)}
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={client?.active}
              label={t("Common-Active")}
              name="active"
              rules={[
                {
                  required: true,
                  message: t("Common-Active"),
                },
              ]}
            >
              <Switch defaultChecked={client?.active} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Métricas del cliente"}</h1>

          <div className={styles.tableWrapper}>
            <h1 className={styles.subTitle}>{t("Common-Segments")}</h1>

            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={clientSegmentsColumn}
              dataSource={client?.segments}
            />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Información de las Compras"}</h1>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Promociones"}</h1>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Unidades de acumulación"}</h1>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Recomendaciones de productos"}</h1>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{"Recompensas"}</h1>
        </div>
      </div>
    </div>
  );
};

export default ViewClient;
