import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createClientSegment,
  getAllCitiesWithExistingClients,
  getAllCountriesWithExistingClients,
  getAllSexTypes,
  getBranchOfficesFiltered,
  getBrandsFiltered,
  getCategoriesFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Form, message, Select, Button, Input, InputNumber, Spin } from "antd";

import styles from "./NewClientSegment.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";

const NewClientSegment = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [sexTypes, setSexTypes] = useState<any[]>([]);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_CLIENTS)) {
      handleFetchCountries();
      handleFetchSexTypes();
      handleFetchCities();
    }
  }, []);

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const handleFetchCountries: () => any = async () => {
    const response = await getAllCountriesWithExistingClients();

    setCountries(response);
  };

  const handleFetchCities: () => any = async () => {
    const response = await getAllCitiesWithExistingClients();

    setCities(response);
  };

  const handleFetchSexTypes = async () => {
    const response = await getAllSexTypes();
    setSexTypes(response);
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleSearchBrands = async (value: string) => {
    if (value.length > 2) {
      const response = await getBrandsFiltered(value);
      setBrands(response);
    } else setBrands([]);
  };

  const getSexTypesOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });
    return options;
  };

  const getCountriesOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });
    return options;
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };
  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
          ? item.parents.filter(Boolean).join(" - ")
          : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;

      options.push(
          <Select.Option value={item.id} key={item.id}>
            {categoryTree}
          </Select.Option>
      );
    });

    return options;
  };


  const handleCreateClientSegment = async () => {
    setLoading(true);

    const data = form.getFieldsValue();
    const articleList = articlesSelected.map((article) => article.id);

    let response = await createClientSegment(
      data.name,
      data.description ?? "",
      data.minAge,
      data.maxAge,
      data.sex,
      data.countryIds ?? [],
      data.stateIds ?? [],
      articleList ?? [],
      data.brandsIds ?? [],
      data.categoriesIds ?? [],
      data.branchOfficesIds ?? []
    );
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      window.location.href = "/promotions/clientsegments";
    } else message.error(t("Common-CreateErrorMessage"));

    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewClientSegment")}</h1>
        <Button
          onClick={handleCreateClientSegment}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.newWrapper}>
          <Form
            name="newSegment"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={handleCreateClientSegment}
            form={form}
            autoComplete="off"
            className={styles.formNew}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-Description")} name="description">
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Sex")}
              name="sex"
            >
              <Select placeholder={t("Common-Sex")} allowClear>
                {getSexTypesOptions(sexTypes)}
              </Select>
            </Form.Item>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-MinAge")}
                name="minAge"
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-MinAge")}
                />
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-MaxAge")}
                name="maxAge"
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-MaxAge")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Country")}
                name="countryIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-SelectOneOrMore")}
                  // onChange={handleChange}
                  className={styles.multipleSelect}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getCountriesOptions(countries)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-City")}
                name="stateIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-SelectOneOrMore")}
                  // onChange={handleChange}
                  className={styles.multipleSelect}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getCountriesOptions(cities)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Brands")}
                name="brandsIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-SearchByName")}
                  onSearch={handleSearchBrands}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(brands)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-Categories")}
                name="categoriesIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficesIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
            </div>
            <AddArticleModal
              selectedArticles={articlesSelected}
              onAddArticle={handleAddArticle}
              onRemoveArticle={handleRemoveArticle}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewClientSegment;
