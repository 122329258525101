import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createClient,
  deleteClient,
  getClients,
  getAllIdTypes,
  getAllSexTypes,
  getAllCountries,
  getAllStatesByCountry,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Form,
  Input,
  Button,
  message,
  Modal,
  Select,
  Spin,
  DatePicker,
  Dropdown,
  Menu,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./Clients.module.scss";
import { useNavigate } from "react-router-dom";

const Clients = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClients();
    }
  }, []);

  const handleFetchClients = async (pagination = actualPage) => {
    const response = await getClients(pagination);
    console.log(response);
    setData(response.clients);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteClient(itemId);
      },
    });
  };

  const handleDeleteClient = async (clientId: number) => {
    setLoading(true);
    const deleteStatus = await deleteClient(clientId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchClients();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchClients(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Identifier"),
      dataIndex: "idNumber",
    },
    {
      title: t("Common-BirthDate"),
      dataIndex: "birthdate",
    },
    {
      title: t("Common-RegisterDate"),
      dataIndex: "createdAt",
      render: (date: string) => date?.split("T")[0],
    },
    {
      title: t("Common-Level"),
      key: "level",
    },
    {
      title: t("Common-Segments"),
      key: "segments",
    },
    {
      title: t("Common-TotalAccumulationUnits"),
      dataIndex: "accumulatedUnits",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="view"
                onClick={() => {
                  navigate(`/promotions/clients/${record.id}/view`);
                }}
                icon={<EyeOutlined />}
              >
                {t("Common-View")}
              </Menu.Item>

              {hasPermission(privilegesEnum.DELETE_CLIENTS) && (
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    showDeleteConfirm(record.id);
                  }}
                  icon={<DeleteOutlined />}
                >
                  {t("Common-Delete")}
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            onClick={(e) => e.preventDefault()}
            className={styles.buttonMore}
          >
            <MoreOutlined className={styles.moreIcon} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Clients")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={() => {
                navigate("/promotions/clients/create");
              }}
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button className={styles.importButton} type="primary">
              {t("Common-DownloadCsv")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_CLIENTS) && (
          <div className={styles.tableWrapper}>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={data}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Clients;
